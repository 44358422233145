.projects {
  background-color: var(--color-background);
}

.projects .section-title {
  margin-bottom: 16px;
}

.projects .list-project {
  gap: 36px;
  transition-duration: 500ms;
}

.projects .project-wrapper {
  min-width: 100%;
  margin-right: 60px;
  transition-duration: 400ms;
}

.projects .project {
  text-align: start;
  color: var(--color-text-gray-ligth);
  width: 90%;
  padding: 16px;
  gap: 12px;
  transition-duration: 1s;
  border-radius: 4px;
  background-color: var(--color-background-ligth);
}

.projects .list-project .project-wrapper:nth-child(even) .project {
  flex-direction: row-reverse;
}

.projects .project img {
  width: 100%;
  border-radius: 4px;
}

.projects .project h3 {
  font-size: 2rem;
  color: white;
  margin-bottom: 8px;
}

.projects .project .project-features {
  margin-bottom: 8px;
}

.projects .project .project-features li {
  padding: 4px 8px;
  margin-right: 6px;
  margin-bottom: 3px;
  border-radius: 2px;
  background-color: var(--color-background-dark);
}

.projects .project .project-features li:hover {
  cursor: pointer;
  font-weight: 400;
  transition-duration: 200ms;
  background-color: var(--color-primary-dark);
}

.projects .project .project-features li:last-child {
  margin-right: 0;
}

.projects .project .project-description {
  text-overflow: ellipsis;
  overflow: auto;
  max-height: 150px;
}

.projects .project .project-links {
  margin-top: 12px;
  gap: 8px;
}

.projects .project .project-links button {
  height: 100%;
  border: 0;
  cursor: pointer;
  color: white;
  padding: 8px 12px;
  border-radius: 2px;
  min-width: 90px;
  background-color: var(--color-primary-dark);
}

.projects .project .project-links button:hover,
.projects .project .project-links button:active {
  background-color: var(--color-primary);
  transition-duration: 600ms;
}

@media screen and (min-width: 768px) {
  .projects .project img {
    width: 50%;
    object-fit: contain;
  }

  .projects .project .project-content {
    flex: 1;
  }

  .projects .project .project-description {
    max-height: none;
    flex: 1;
  }
}
