.App {
  text-align: center;
  overflow: hidden;
}

.btn-action {
  color: white;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 1rem;
  padding: 8px 32px;
  border-radius: 3px;
  background: linear-gradient(90deg, #9f101a, #c31925);
}

.btn-action:hover,
.btn-action:active {
  border: 1px solid var(--color-primary);
  transition-duration: 400ms;
}

.section {
  padding: 32px;
  background-color: var(--color-background-dark);
  position: relative;
  top: -2px;
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-text-white);
}

.list-icons {
  display: flex;
  gap: 12px;
}

.social-icon {
  font-size: 1.6rem;
  color: #484848;
}

.social-icon:hover,
.social-icon:active {
  color: var(--color-primary);
  transition-duration: 400ms;
}

.navigation-icon {
  cursor: pointer;
  position: absolute;
  padding: 16px 12px;
  border-radius: 4px;
  color: var(--color-text-gray-ligth);
  background-color: rgba(60, 59, 59, 0.6);
}

.navigation-icon:hover,
.navigation-icon:active {
  background-color: rgba(60, 59, 59, 1);
  transition-duration: 200ms;
}

.navigation-icon.back {
  left: 12px;
}

.navigation-icon.next {
  right: 12px;
}
