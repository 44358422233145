* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-primary-dark: #bf1824;
  --color-primary: #ef403f;
  --color-ligth: #ff0214;
  --color-text-white: #fff;
  --color-text-gray: #868686;
  --color-text-gray-ligth: #bdbdbd;
  --color-background-dark: #111;
  --color-background: #181818;
  --color-background-ligth: #1e1e1e;
}

.ligth-mode {
  --color-text-white: #000; /* previously white text is now black */
  --color-text-gray: #121212; /* gray text is now slightly lighter */
  --color-text-gray-light: #0b0a0a; /* light gray text is now dark gray */
  --color-background-dark: #eee; /* dark background is now light */
  --color-background: #f3f3f3; /* standard background is now lighter */
  --color-background-light: #fff;
}

.ligth-mode .headline {
  background: var(--color-background-light);
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}
