.about {
  flex-direction: column-reverse;
  color: var(--color-text-gray-ligth);
  background-color: var(--color-background-dark);
}

.about p {
  max-width: 500px;
  margin-top: 16px;
}

.about .list-icons {
  margin-top: 24px;
}

.about .list-about {
  margin-top: 24px;
  font-size: 1.3rem;
  color: var(--color-text-gray-ligth);
}

.about .list-about .learning {
  color: var(--color-primary);
}

.about .list-about h3 {
  margin-top: 1.8rem;
  font-size: 2rem;
  color: var(--color-primary-dark);
}

.about .list-about .txt-frame-work {
  font-size: 1.8rem;
}

.about .list-about ul {
  padding-top: 6px;
  padding-left: 6px;
}

.about .btn-action {
  margin-top: 44px;
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .about {
    text-align: start;
    flex-direction: row;
  }

  .about main {
    z-index: 99;
    width: 60%;
  }

  .about .list-icons {
    justify-content: flex-start;
  }

  .about .list-about {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px;
    font-size: 1rem;
  }

  .about .list-about > * {
    width: 45%;
  }

  .about .list-about h3 {
    margin-top: 1rem;
    font-size: 1.6rem;
  }

  .about .list-about .txt-frame-work {
    width: max-content;
    font-size: 1.6rem;
  }
}
