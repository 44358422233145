.recommandations {
  color: var(--color-text-gray-ligth);
  text-align: start;
}

.recommandations .list-recommandation {
  padding-left: 60px;
  transition-duration: 500ms;
}

.recommandations .recommandation-wrapper {
  min-width: 100%;
  margin-right: 60px;
  opacity: 0;
  transition-duration: 400ms;
}

.recommandations .recommandation-wrapper.active {
  opacity: 1;
}

.recommandation {
  width: 90%;
  max-width: 800px;
  margin-top: 24px;
}

.recommandation img {
  margin-right: 8px;
  margin-bottom: 8px;
  width: 25%;
  max-width: 140px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  border: 2px solid var(--color-background-ligth);
}

.recommandation .recommandation-info {
  padding: 8px;
  flex: 1;
  background-color: var(--color-background-ligth);
}

.recommandation .recommandation-name {
  color: var(--color-text-white);
}

.recommandation .recommandation-title {
  color: var(--color-primary);
  font-style: italic;
}

.recommandation .recommandation-name:hover,
.recommandation .recommandation-title:hover {
  text-decoration: underline;
}

.recommandation .recommandation-content {
  padding: 8px;
  background-color: var(--color-background-ligth);
}

.recommandation .recommandation-content .recommandation-description {
  max-height: 250px;
  overflow: auto;
}

@media screen and (min-width: 768px) {
  .recommandation .recommandation-info {
    padding: 24px;
  }

  .recommandation .recommandation-name {
    font-size: 1.8rem;
  }

  .recommandation .recommandation-content {
    padding: 24px;
  }
}
