.headline {
  min-height: 400px;
  /* height: 100vh; */
  background-color: var(--color-background-dark);
  background-image:
    radial-gradient(
      farthest-corner at 8rem 12rem,
      #3c3b3b 0%,
      #111 60%
    );
}

.headline .container {
  padding-top: 160px;
  padding-bottom: 120px;
}

.headline .container .name,
.headline .container .role {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-text-white);
}

.headline .container .role {
  color: var(--color-primary);
  text-shadow: 3px 4px 16px var(--color-primary-dark);
}

.headline p {
  max-width: 900px;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  color: var(--color-text-gray);
}

.headline .btn-action {
  margin-top: 3rem;
}

.headline .btn-action.resume {
  margin-left: 1rem;
  background: var(--color-primary-dark);
}

@media screen and (min-width: 768px) {
  .headline {
    background-image:
      radial-gradient(
        farthest-corner at 20rem 10rem,
        #3c3b3b 0%,
        #111 60%
      );
  }

  .headline .container .name,
  .headline .container .role {
    font-size: 3.6rem;
  }

  .headline p {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 1.6rem;
  }
}
