.list-block-wrapper {
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.list-block-wrapper .overlay {
  pointer-events: none;
  position: absolute;
  z-index: 98;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #111, #1110, #1110);
}

.list-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 120px;
}

.list-blocks {
  width: 200px;
  margin-top: 170px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin-right: 40px;
}

.block-wrapper {
  width: 240px;
  height: 40px;
  cursor: pointer;
  position: relative;
  background-color: #2b2b31;
  z-index: var(--i);
  display: flex;
  justify-content: center;
  border-bottom: 0.4px solid #51515152;
  transition-duration: 500ms;
}

.block-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: -34px;
  height: 100%;
  width: 36px;
  background-color: #1c1f20;
  transform-origin: right;
  transform: skewY(70deg);
  transition-duration: 500ms;
}

.block-wrapper::after {
  content: '  ';
  font-size: 0.8rem;
  position: absolute;
  width: 100%;
  height: 100px;
  top: -99px;
  left: 1.5px;
  background-color: #2d2f35;
  transform-origin: bottom;
  transform: skewX(20deg);
  transition-duration: 500ms;
}

.block-wrapper.active {
  transform: translateX(190px);
}

.block-wrapper p {
  width: 70%;
  height: 80px;
  padding: 20px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25rem;
}

.block-static {
  min-height: 400px;
  border-bottom: 0;
  background-color: transparent;
  background-image: linear-gradient(180deg, rgb(36 38 42), rgb(42, 43, 48, 0));
}

.block-static::before {
  background-color: transparent;
  background-image: linear-gradient(180deg, rgb(28 30 31), rgb(35, 37, 38, 0));
}

.block-static::after {
  background-color: #27292d;
}

.block-dynamic:hover {
  background-color: #37373e;
}

.block-dynamic:hover::before {
  background-color: #262a2b;
}

.block-dynamic:hover::after {
  background-color: #393c43;
}

.block-details {
  color: white;
  content: '  ';
  font-size: 0.8rem;
  position: absolute;
  width: 75%;
  height: 100%;
  top: -90px;
  left: 0;
  transform-origin: bottom;
  transform: skewX(20deg);
  z-index: 97;
  border-radius: 4px;
}

.block-details h4 {
  color: var(--color-primary);
}

.block-details p {
  width: 100%;
  margin: 0;
  padding: 8px;
  font-size: 0.8rem;
}
