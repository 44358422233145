.header {
  color: var(--color-text-gray);
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
}

.list-nav {
  position: fixed;
  gap: 16px;
  padding: 16px;
}

.list-nav li {
  cursor: pointer;
  opacity: 0.7;
  z-index: 101;
}

.list-nav li:hover {
  color: var(--color-primary);
  opacity: 1;
}

.list-nav li span {
  display: none;
}

/* Only on for mobile */
@media screen and (max-width: 768px) {
  .navbar {
    align-items: center;
    position: fixed;
    width: 100%;
    opacity: 0;
    transform: translateX(120%);
    transition: all 0.6s ease-in-out;
    background-color: var(--color-background-dark);
  }

  .navbar.active {
    opacity: 1;
    transform: translateX(0);
  }

  .list-nav {
    width: 84%;
    flex: 1;
    margin: 64px 32px;
    flex-direction: column;
  }

  .list-nav li {
    padding: 24px 0;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: start;
    border-bottom: 1px solid var(--color-text-gray);
  }

  .list-nav li span {
    display: block;
    margin-left: 1rem;
  }

  .btn-menu {
    top: 32px;
    right: 32px;
    font-size: 1.6rem;
    position: fixed;
    z-index: 9;
    opacity: 0.7;
    color: var(--color-text-gray);
  }
}
