.contact {
  top: -2px;
  background-color: var(--color-background);
}

.contact .contact-form {
  width: 80%;
  color: var(--color-text-white);
  max-width: 400px;
  margin-top: 40px;
}

.contact .contact-form input,
.contact .contact-form textarea {
  border: 2px solid transparent;
  outline: 0;
  border-radius: 4px;
  margin-top: 12px;
  padding: 12px 18px;
  color: var(--color-text-white);
  background-color: var(--color-background-dark);
}

.contact .contact-form input:focus,
.contact .contact-form textarea:focus {
  font-weight: 700;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.contact .contact-form .btn-action {
  margin-top: 24px;
}
