::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

/* Customize the track color */
::-webkit-scrollbar-track {
  background-color: var(--color-background-dark);
}

/* Customize the thumb color and shape */
::-webkit-scrollbar-thumb {
  background-color: var(--color-text-gray);
  border-radius: 5px;
}

/* Change the thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
